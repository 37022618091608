.robot-wrapper {
  background: #e9ebee;
  font-family: Helvetica, Arial, sans-serif;
  padding: 88px 0 176px;
}

.robot-container {
  width: 612px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid #dddfe2;
  border-radius: 3px;
  border-color: #d1d2d5 #d1d2d5 #c5c6c8 #c5c6c8 !important;
}

.robot-header-mobile {
  display: none;
}

.robot-footer-desktop{
  padding-top: 40px;
}

.footer-flex-mobile-robot {
  display: none;
}

.robot-icons {
  display: flex;
  align-items: center;
  padding: 12px 12px 0 12px;
}

.robot-progress-bar {
  margin-top: 6px;
}

.robot-icon {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/7fWdGRc-WyK.png);
  background-size: auto;
  background-repeat: no-repeat;
  display: inline-block;
  height: 256px;
  width: 256px;
  background-position: 0 0;
  margin: 12px 0;
}

.robot-text h2 {
  padding-bottom: 4px;
  margin-bottom: 0;
  font-weight: 600;
  color: #1d2129;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
}

.robot-icon-mobile img{
  width: 135px;
}

.robot-container .percent-text{
  color: gray;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
}

.robot-text p {
  margin-top: 4px;
  padding-bottom: 4px;
  color: #1d2129;
  font-size: 14px;
  line-height: 18px;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 0;
}

.robot-text {
  padding-top: 12px;
  margin: 0 12px;
}

.robot-footer {
  text-align: right;
  border-top: 1px solid #dddfe2;
  margin: 0 12px;
  padding: 12px 0;
}

.robot-footer button {
  margin-left: 4px;
  background-color: #365899;
  border-color: #365899;
  cursor: pointer;
  color: #fff;
  border: 1px solid;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: bold;
}

.robot-footer-mobile {
  display: none;
}

@media only screen and (max-width: 769px) {
  .robot-container {
    width: 100%;
  }

  .robot-icons {
    flex-direction: column;
  }

  .robot-footer {
    border: none;
  }

  .robot-icon {
    background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/7fWdGRc-WyK.png);
    background-size: 129px 258px;
    background-repeat: no-repeat;
    display: inline-block;
    height: 128px;
    width: 128px;
    margin-bottom: 8px;
    background-position: 0 0;
  }

  .robot-text {
    padding-top: 0;
  }

  .robot-text h2 {
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    color: #4b4f56;
  }

  .robot-footer {
    display: none;
  }

  .robot-icon-mobile {
    height: 128px;
    margin-bottom: 16px;
  }

  .robot-footer-mobile {
    display: block;
  }

  .robot-footer-mobile button {
    text-shadow: none;
    background-color: #4080ff;
    background-image: none;
    border-radius: 2px;
    display: block;
    width: 100%;
    color: #fff;
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    border: none;
    border-radius: 2px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  .robot-footer-mobile {
    padding-top: 8px;
  }

  .robot-wrapper {
    height: 100vh;
    padding: 8px 8px 0px 8px;
  }

  .robot-text {
    margin: 0;
  }

  .robot-text p {
    margin-bottom: 0;
    padding-bottom: 0;
    border-top: 1px solid #e5e5e5;
    padding-top: 12px;
    color: #000000;
    border-top-color: rgba(0, 0, 0, 0.101);
  }

  .robot-icons {
    padding: 12px;
  }

  .robot-text h2 {
    padding-bottom: 12px;
  }

  .robot-icon {
    margin-top: 0;
  }

  .footer-flex-mobile-robot {
    display: block;
  }

  .footer-flex-mobile-robot {
    display: flex;
    justify-content: space-around;
    padding: 5px 8px;
    background-color: #fff;
    border-color: #e9e9e9;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 8px;
  }



  .footer-flex-mobile-robot h2 {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #90949c;
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
  }

  .footer-flex-mobile-robot p {
    color: #576b95;
    cursor: pointer;
    text-decoration: none;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
  }

  .footer-flex-mobile-robot button {
    border: 1px solid #3b5998;
    border-radius: 3px;
    color: #3578e5;
    display: block;
    font-size: large;
    height: 18px;
    text-align: center;
    width: 6px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 9px;
  }

  .robot-icons {
    border-color: rgba(0, 0, 0, 0.07) rgba(0, 0, 0, 0.11) rgba(0, 0, 0, 0.18);
  }

  .robot-header-mobile {
    display: block;
  }

  .left-icon {
    background-position: 0 -80px;
    background-repeat: no-repeat;
    left: 10px;
    pointer-events: none;
    position: absolute;
    top: 12px;
    background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yk/r/UdQhqb1FWR3.png);
    background-size: 103px 194px;
    height: 20px;
    width: 20px;
  }

  .robot-header-mobile {
    background: #3b5998;
    height: 44px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 16px;
    line-height: 44px;
    font-family: Arial, Helvetica, sans-serif;
  }

  .hide-on-mobile{
    display: none;
  }
  
  .robot-footer-desktop{
    display: none;
  }

  .left-icon{
    display: none;
  }

  .robot-footer-mobile button{
    display: none;
  }

}
